.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    padding: 0 5px;
    color: #6c757d;
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: #007bff;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
  }
  