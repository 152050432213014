/* privacy policy */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background:#E2A6A9;
  border-radius: 5px;
}
.doc::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: white;
}

.doc::-webkit-scrollbar-thumb {
  background:#ffffff00;

  border-radius: 5px;
}
.text-justi{
  text-align: justify;
  text-justify: inter-word;
}
@font-face {
  font-family: 'MyFont';
  src: url('../public/assets/font/GTWalsheimProRegular.OTF') format('opentype');
       /* url('../public/assets/font/') format('woff'),
       url('../fonts/MyFont.ttf') format('truetype'); */
  
}
*{
  font-family: 'MyFont';
  line-height: 1.5;
}

html {
  overflow-x: hidden;
}
a {
  color: #313030;
  text-decoration: none;
  transition: 0.3s all ease;
}

a:hover {
  color: #1c03ba;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-heading {
 
  font-family: "MyFont";
  color: #000;
}

.container {
  z-index: 2;
  position: relative;
}

.text-black {
  color: #000 !important;
}

.text-primary {
  color: #034675 !important;
}

.border-top {
  border-top: 1px solid #f2f2f2 !important;
}

.border-bottom {
  border-bottom: 1px solid #f2f2f2 !important;
}

figure figcaption {
  margin-top: 0.5rem;
  font-style: italic;
  font-size: 0.8rem;
}

section {
  overflow: hidden;
}

.section {
  padding: 7rem 0;
}

.section-heading {
  /* font-size: 2rem; */
  font-weight: 700;
  background: linear-gradient(-40deg, #3db3c5, #274685);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.front-img{
  width: 150%;
  height:95%;
 
}


/* Default btn sre-tyling */
.btn {
  border: none;
  /* padding: 15px 30px !important; */
}

.btn.btn-outline-white {
  border: 2px solid #fff;
  background: none;
  color: #fff;
}

.btn.btn-outline-white:hover {
  background: #fff;
  color: #663e4d;
}

.btn.btn-primary1 {

 
  background-color: #862c30;
  color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}
.btn.btn-primary2 {
  background-color: #862c30;
  color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}

/* Feature 1 */
.feature-1 .wrap-icon {
  margin: 0 auto;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.feature-1 .wrap-icon.icon-1 {
  
  background-color: #862c30;


}
.tick{
  color: #862c30;
}
.feature-1 .wrap-icon i {
  font-size: 20px;
  line-height: 0;
  color: #fff;
}

.feature-1 h3 {
  font-size: 20px;
}

.feature-1 p {
  color: #4d4d4d;
}

/* Step */
.step {

  padding: 50px;
  
}

.step .number {
  background: linear-gradient(-45deg, #1391a5, #274685);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  letter-spacing: 0.2rem;
  margin-bottom: 10px;
  display: block;
}

.step p:last-child {
  margin-bottom: 0px;
}

/* =============================================== */
                /* Login */
/* =============================================== */
.login-section{
  max-height: 100vh;
  background-image: linear-gradient(to left, #db9194, #f6f6f600);
}

.login-row{
  min-height: 90vh;
}
.login-box{

 
   min-height: 400px !important;

   height:450px !important;

   max-height: 450px !important;
}

/* =======================================================
                Blog
 ======================================================   */
 .blog-page{
  /* background-color: #e3c2c3; */
   background-color: #FBF1E9;
 }

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.login-drop>li>Link:hover{
 color: #000;

}
.navbar {

}

.dropdown-item.btn-primary1 {
  color: white; /* Default text color */
  transition: color 0.3s ease; /* Smooth transition effect */
}

.dropdown-item.btn-primary1:hover {
  color: black !important;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.front-section{
  background-color: #db9194 ;
   min-height: 90vh !important;
}

.hero-main {
  /* background: linear-gradient(to right, #05ebd5, rgba(61, 179, 197, 0.8) 100%),
    url(/public/assets/img/hero-bg.jpg); */
  
background-color: #db9194 ;
  /* height: 80vh;
  min-height: 650px; */
}
.main-para{
  font-size: 20px;
  width:80%;
}
.home-col1{
  height: 100vh;
  min-height: 600px;
  align-content: center;
}
.home-col2{
  height: 80vh;
  min-height: 400px;
  align-content: center;
}

@media screen and (max-width: 992px) {

  .home-col1{
    height:65vh;
    min-height: 600px;  
  }



 }

@media screen and (max-width: 576px) {

  .home-col1{
    padding-top:150px;
  }
  .main-para{
    font-size:16px;
  }
 }

.unordered-list {
  list-style: none;
  /* width: 250px; */
  /* width: 70%; */
  /* font-size: 22px; */
}



.list-home:hover{
  background-color: #805fd2;
}
.li-link {
  text-decoration: none;
 
}
.good-text{
  color:  #862c30;
}
.hero-section .wave {
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  bottom: -150px;
}
.dow-img{
  height: 80px;

  width: 120px;
}




.step-box{
  background-color: #E7DFDF;
}
.download-img1{
margin-left: 200px;
padding-left: 100px;
}
/* .link-pp:hover{
color: #1391a5;
}  */

/*=========================================================
    404 page
============================================================*/


.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #642626;
    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px;}


/* ============================================================================== */

/* ========================= Patients section ========================= */
.patients-section {
  height: 100vh;
  position: relative;
  background-color: #db9194 ;

  min-height: 800px;
  
}

.scroll {
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar {
  display: none;

}
.scroll {
  scrollbar-width: none;
}
.div1-scroll{
  position: sticky;
  overflow-y: auto; 
  top: 15%;
  
  /* transform: translateY(-50%); */
  width: 100%; 
 
}
@media (max-width: 768px) {
  .div1-scroll {
    position: static; 
    margin-bottom: 20px;
  top:10%;
     
  }
  .timeline-container{
 
  }
}

.patient-stripe {
  background-color: #fbf1e9;
  align-items: center;
  text-align: center;
  width: 100%;
}
.stripe-bar {
  padding: 25px 0px 25px 0px;
}

.person-img img {
  height: 200px;
  width: 200px;
}
/* Step */
.step-p {
 
  background: #fff;
  padding: 10px;
}

.step-p .number {
  background: linear-gradient(-45deg, #1391a5, #274685);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  letter-spacing: 0.2rem;
  margin-bottom: 10px;
  display: block;
}

.step-p p:last-child {
  margin-bottom: 0px;
}
.feedback1 {
  background-color: #fbf1e9;
}
.unordered-li {
  list-style: none;
}
.step-images{
max-width: 300px;
max-height: 300px;
}
/* ============================================================================================ */
/* ===================== Header +++=================== */
/* ============================================================================================ */
img.logo1{
 max-height: 50px;
 max-width: 140px;
}
.nav-item a{
  font-weight:500;

  font-size: 16px;
}



/* ================================================================================================ */
/* ============ footer ===================== */
/* ================================================================================================= */

.footer {

   background-color:#862c30;
  color: #ffffff;
}
.download-img {
  height: 45px;
  width: 160px;
  
}
.site-section > div > ul > li > a {
  text-decoration: none;
  color: #ffffff;
}
.site-section > div > ul {
  overflow-x: hidden;
}


.footer ul li {
  margin-bottom: 15px;
}

.footer a {
  color: #000;
}

.footer .copyright {
  margin-bottom: 0px;
}

.footer .copyright,
.footer .credits {
  font-size: 14px;
}

.social a {
  display: inline-block;
  width: 30px;
  height: 3%;
  border-radius: 50%;
  background: #f8f9fa;
  position: relative;
  text-align: center;
  transition: 0.3s background ease;
  color: #0d1e2d;
  line-height: 0;
}

.social a span {
  display: inline-block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.social a:hover {
  color: #fff;
  background: #2d71a1;
}

.social a i {
  line-height: 0;
}

img.footer-img{
 max-height: 70px;
 max-width: 130px;

}
.email-text:hover{
    background-color: #3d34ec;
}

/* ======================================================================================================= */
/* ======================================== Contact us page ============================================== */
/* ======================================================================================================= */

#contactus {
 
  background-color: #db9194;
}

.contact-p {
  background-color: #fbf1e9;
}
.contact-p a {
  text-decoration: none;
  color: black;
}

.card-img-top {
  height: 250px;
  width: 250px;
}
 a:hover{
  color: #28669f;
 }

/* ======================================================= */
/*  ==========  About  us Page ================ */
/* ===================================================================== */

.about-us {
/* 
  background-image: linear-gradient(to left, #db9194, #f6f6f600); */
  background-color: #db9194 ;
  height: 100vh;
  min-height: 800px;
}
.about-row {
  height: 100vh;
  align-items: center;
}
button.arrow {
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  height: 50px;
  width: 50px;
  padding: 10px;
  border: 1px solid rgb(6, 6, 18);
}
.team-img{
 height: 300px;
  width: 300px;
}
.card1{
  height: 400 px;
  
}
.bg-body-tertiary1{
  background-color:  #fbf1e9;
}

/* ================================================================================================ */
/*  ===================================================== docotor page ================ */
/* ================================================================================================ */
.doctor-section{
  
  background-size: cover;

 background-position: center;
 background-color: #db9194 ;  
  /* /* height: 100vh; */
  min-height: 600px; 
}
@media screen and (max-width: 768px) {

  .doctor-section{
    margin-top: 20%;
  }

 }
.doc-coloum{
  
}
.doctor-img{
  align-items:center;
}

/* ================================================================================================ */
/*     clinic page  */
/* ================================================================================================ */
.clinic-section{
  background-color: #db9194 ;
 
   min-height: 600px;
 }
 @media screen and (max-width: 768px) {

  .clinic-section{
    margin-top: 20%;
  }

 }
 /* ====================================================================
                   write  blog page                      
============================================================================== */
 img.blog-img1{
  
}
.blog-section{
  
}

.newline:after {
  content: '\A';
  white-space: pre;
}

.newline:first-of-type:after {
  content: '';
}



/* ============================================ 
                  blog page  card       
================================================*/
.card1{
  max-width: 380px;

}
.blog-card{
  background-color:#ffffff;
  height: 600px;
}
.cotegory {
  display: inline-block;
}
.blog-written-by{
  width: 20%;
}
.blog-img{
  height:250px;
  width: 250px;
}

/* ========================================================================
                      blog detailed page layout
=============================================================================*/
.blog-header{
   background-color: #db9194 ;
    border-radius: 0px 0px 50px 50px;
}
.sub-bg{
   background-color: #fbf1e9;
    padding: 20px;
     border-radius:  10px;
}
 .justify{
  text-align: justify;
 }
 /*  css for the vitals cards */
.vital-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

.vital-card {
  flex: 1 1 calc(100% / 6 - 1rem);
  max-width: calc(100% / 6 - 1rem);
  min-width: 150px;
}

@media (max-width: 1200px) {
  .vital-card {
    flex: 1 1 calc(100% / 4 - 1rem);
    max-width: calc(100% / 4 - 1rem);
  }
}

@media (max-width: 768px) {
  .vital-card {
    flex: 1 1 calc(100% / 2 - 1rem);
    max-width: calc(100% / 2 - 1rem);
  }
}

@media (max-width: 480px) {
  .vital-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.btn1{
  padding:5px !important;
}
#background{
  background-color: #da9c9e;
  padding: 7px !important;
}

@media screen and (max-width: 786px) {
  #location {
    display: none !important;
  }
}

.dropdown:hover > .dropdown-menu{
  display: block;
  margin-top: 0.125em;
  margin-left: 0.125em;
}

.dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.100em;
  margin-left: 5.5em;
}

/* =============== Medicine Scheduler Page ============================================
======================================================================================== */
/* medicine scheduler page code */

.front-banner{
  background-image: url('../public/assets/img/webimg/medicinebanner1.png'); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


/* =============== Patient Page ============================================
======================================================================================== */
/* Patient page code */

.front1-banner{
  background-image: url('../public/assets/img/webimg/patientbanner.png'); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.css-19kzrtu{
  padding: 0px !important;
  margin-top: 20px;
 margin-bottom: 20px;
}
.tbl-head{
background-color: #efeded !important;
}
.footer-1{margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgba(0, 0, 0, 0.6);
  }
  /* Time Picker */

  .custom-time-picker {
    height: 40px; 
  }
  
  .css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8px !important;
}