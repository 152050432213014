
/*  css for the vitals cards */
.vital-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }
  
  .vital-card {
    flex: 1 1 calc(100% / 6 - 1rem);
    max-width: calc(100% / 6 - 1rem);
    min-width: 150px;
  }
  
  @media (max-width: 1200px) {
    .vital-card {
      flex: 1 1 calc(100% / 4 - 1rem);
      max-width: calc(100% / 4 - 1rem);
    }
  }
  
  @media (max-width: 768px) {
    .vital-card {
      flex: 1 1 calc(100% / 2 - 1rem);
      max-width: calc(100% / 2 - 1rem);
    }
  }
  
  @media (max-width: 480px) {
    .vital-card {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  

  /* ======================================================================================= */
                    /*  lOGLIN pAGE WITH OTP AND  LOGIN WITH PASSWORD PAGE  */
  /* ======================================================================================= */
  
  .login-container {
    height: 100vh;
    min-height: 600px;
    width: 100%;
    /* background-color: #dffcfa; */
     background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
   .border2{
    border: 1px solid rgb(175, 175, 175);
    border-left: 0px solid black;
    border-radius: 0px 5px 5px 0px ;
    
   }
   .border1{
    border: 1px solid rgb(175, 175, 175);
    border-right: 0px solid black;
    border-radius: 5px 0px 0px 5px ;
    
   }
  .login-box {
    height: 400px;
    min-height: 300px;
    width: 700px;
    border-radius: 20px;
  }
  
  .logo-img {
    max-height: 200px;
    max-width: 200px;
    /* background-color: #05ebd5; */
  }
  
  .login-col-1 {
    /* background-color: #19ccba; */
    background-color: #e2a6a9;
  }
  
  .eyebutton{
    position: relative;
    top:30px;
    left: 280px;
    width: 10px;
   
  }

  .text-color{
    color:#DB9194;
  }
  .background{
    background-color: #DB9194;
  }
  #background{
    background-color: #e2a6a9;
  }


  
  /*  remove cancel an ok button from calender  */
  .css-19kok5i-MuiDialogActions-root{
    display: none !important;
  }

  .patients-card{
    min-height: 180px;
    min-width: 150px;
  }



  /* / clanedr horizonatal  */
  .calendar-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .nav-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .month-label {
    font-size: 18px;
    font-weight: bold;
    color: #003366;
  }
  
  .calendar-scroll {
    display: flex;
    overflow-x: auto;
  }
  
  .calendar-week {
    display: flex;
    justify-content:space-between !important;
    align-items: center;
    width: 100%;
  
  }
  
  .day {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 10px;
    min-width: 50px;
    cursor: pointer;
    border-radius: 0px;
    border-right: 1px solid rgba(135, 135, 135, 0.674);
    transition: background-color 0.3s ease;
  }
  
  .day:hover {
    background-color: #f0f0f0;
  }
  
  .date {
    font-size: 18px;
    font-weight: bold;
  }
  
  .day-name {
    font-size: 14px;
    color: #888;
  }
  
  .selected {
    background-color: #007bff;
    color: white;
  }
  
  .login-passsword{
    min-height: 100vh;
  
  }
  #border2{
    border: 1px solid rgb(175, 175, 175);
    border-left: 0px solid black;
    border-radius: 0px 5px 5px 0px ;
    
   }
   #border1{
    border: 1px solid rgb(175, 175, 175);
    border-right: 0px solid black;
    border-radius: 5px 0px 0px 5px ;
    
   }


   /*================== print invoice ====================*/
   
 @media print {
  /* Ensure the invoice uses the full width of the page */
  .container {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 0;
  }

  /* Use flexbox to align rows horizontally */
  .row {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
  }

  /* Adjust the columns to ensure proper spacing */
  .col-md-3 {
    padding: 8px;
    margin: 0;
    flex: 1;
  }

  .total-price {
    flex: 1; /* Make it span 25% of the row */
  }

  .total-label {
    flex: 3; /* Make it span 75% of the row */
  }
  
  p {
    font-size: 15px; /* Increase text size */
    line-height: 1.5; /* Add line height for better readability */
  }

  /* Add more height to the printed content */
  body {
    font-size: 16px; /* Increase overall body font size */
  }

  /* Ensure the test info section is well spaced */
  .test-info {
    margin-bottom: 10px;
  }

  /* Add border styles for better layout visibility */
  .border, .border-bottom {
    border: 1px solid #ddd;
    padding: 10px; /* Increase padding for better space between content */
  }

  /* Hide elements not needed in print */
  .no-print {
    display: none;
  }

  /* Ensure there is enough space between the rows */
  .row {
    margin-bottom: 10px;
  }

  /* Ensure the button is not printed */
  button {
    display: none;
  }
}